<div>
  <div *ngIf="connectorExists; else notFoundTemplate" class="main-content flex-grow-1 marginn">
    <!-- Header grid -->
    <mat-grid-list cols="3" rowHeight="1.5:1" class="borderGrid">
      <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
      <mat-grid-tile class="title1">{{'qrcode.charging_Station' | translate}}</mat-grid-tile>
      <mat-grid-tile>
        <div class="example-button-row">
          <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- Location and Status Section -->
    <mat-grid-list cols="1" rowHeight="3:1" class="borderGrid">
      <mat-grid-list cols="1" rowHeight="9:1">
        <div class="text-container" style="padding-top: 15px;">
          <span class="title1 title2">{{'qrcode.location' | translate}}</span>
          <ng-container *ngIf="chargingStation; else locationSkeleton">
            <span>{{ chargingStation.siteArea.name }} - {{ chargingStation.site.address.address1 }}</span>
          </ng-container>
          <ng-template #locationSkeleton>
            <div class="skeleton-loader" style="width: 70%; height: 20px;"></div>
          </ng-template>
        </div>
      </mat-grid-list>
      <mat-grid-tile>
        <div class="text-container">
          <span class="text-item title1" id="connector-status">
            {{ 'qrcode.charging_Station' | translate }} : 
            <ng-container *ngIf="chargingStation; else idSkeleton">
              {{ chargingStation.id }}
            </ng-container>
            <ng-template #idSkeleton>
              <div class="skeleton-loader" style="width: 100px; height: 20px; display: inline-block;"></div>
            </ng-template>
          </span>
          <span class="text-item title1" id="connector-status">
            {{ 'qrcode.connector' | translate }} {{ connectorId }} :
            <ng-container *ngIf="connectorStatus; else statusSkeleton">
              <span [ngStyle]="{'color': connectorStatusColor}">
                ({{ connectorStatus }})
              </span>
            </ng-container>
            <ng-template #statusSkeleton>
              <div class="skeleton-loader" style="width: 80px; height: 20px; display: inline-block;"></div>
            </ng-template>
          </span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- Tariff Section -->
    <mat-grid-list cols="3" rowHeight="4:3" class="borderGrid">
      <mat-grid-list cols="1" rowHeight="7:1">
        <mat-grid-tile class="title1 title2">{{'qrcode.tariff' | translate}}</mat-grid-tile>
      </mat-grid-list>

      <div id="mainCoantiner">
        <div class="margin-body pricing-column-wrapper">
          <div class="row">
            <table>
              <tr *ngIf="chargingStation; else flatFeeSkeleton">
                <th>{{'qrcode.FlatFee' | translate}}</th>
                <th>{{ pricingDefinitions.flatFee.price }}€</th>
              </tr>
              <ng-template #flatFeeSkeleton>
                <tr>
                  <th>{{'qrcode.FlatFee' | translate}}</th>
                  <th><div class="skeleton-loader" style="width: 60px; height: 20px;"></div></th>
                </tr>
              </ng-template>

              <tr *ngIf="chargingStation; else energySkeleton">
                <th>{{'qrcode.Energy' | translate}}</th>
                <th>{{ pricingDefinitions.energy.price }}€/kWh</th>
              </tr>
              <ng-template #energySkeleton>
                <tr>
                  <th>{{'qrcode.Energy' | translate}}</th>
                  <th><div class="skeleton-loader" style="width: 60px; height: 20px;"></div></th>
                </tr>
              </ng-template>

              <tr *ngIf="chargingStation; else chargingTimeSkeleton">
                <th>{{'qrcode.ChargingTime' | translate}}</th>
                <th>{{ pricingDefinitions.chargingTime.price }}€/hour</th>
              </tr>
              <ng-template #chargingTimeSkeleton>
                <tr>
                  <th>{{'qrcode.ChargingTime' | translate}}</th>
                  <th><div class="skeleton-loader" style="width: 60px; height: 20px;"></div></th>
                </tr>
              </ng-template>

              <tr *ngIf="chargingStation; else parkingTimeSkeleton">
                <th>{{'qrcode.Parkingtime' | translate}}</th>
                <th>{{ pricingDefinitions.parkingTime.price }}€/hour</th>
              </tr>
              <ng-template #parkingTimeSkeleton>
                <tr>
                  <th>{{'qrcode.Parkingtime' | translate}}</th>
                  <th><div class="skeleton-loader" style="width: 60px; height: 20px;"></div></th>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>
      </div>
    </mat-grid-list>

    <!-- Email Form Section -->
    <mat-grid-list cols="1" rowHeight="3:1" class="borderGrid">
      <mat-grid-tile>
        <form [formGroup]="cardForm">
          <span class="text-item title1">Merci d'entrer une adresse email pour la facturation</span>
          <label class="mt-20" style="width: 100%;">
            <input type="email" autocomplete="off" formControlName="email" class="white large outlined"
              [placeholder]="'name@mail.com' | translate" />
            <div *ngIf="cardForm.controls['email'].invalid && cardForm.controls['email'].touched" class="error">
              <small *ngIf="cardForm.controls['email'].errors?.email" style="color: white;">{{'payment.invalide' | translate}}</small>
            </div>
          </label>
        </form>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- Payment Method Section -->
    <mat-grid-list cols="2" rowHeight="5:1" class="borderGrid">
      <mat-grid-list cols="1" rowHeight="6:1">
        <mat-grid-tile>
          <div class="text-container">
            <span class="text-item title1">{{'qrcode.Paymentmethod' | translate}}</span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="1" rowHeight="5:1">
        <mat-grid-tile class="margin-top">
          <div class="text-container">
            <div class="example-button-row">
              <button class="credit-card" mat-flat-button (click)="redirectToPayment()">{{'qrcode.Use_credit_card' | translate}}</button>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-grid-list>
  </div>
</div>

<ng-template #notFoundTemplate class="marginn main-content">
  <mat-grid-list cols="3" rowHeight="1.5:1" class="borderGrid main-content marginn">
    <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
    <mat-grid-tile class="title1">{{'qrcode.charging_Station' | translate}}</mat-grid-tile>
    <mat-grid-tile>
      <div class="example-button-row">
        <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="2" rowHeight="4:2" class="borderGrid main-content marginn">
    <mat-grid-list cols="1" rowHeight="2:1">
      <mat-grid-tile>
        <div class="text-container">
          <span class="notfound">{{'qrcode.ConnectorNotFound' | translate}}</span>
          <span class="text-item title1">{{'qrcode.call_or_message' | translate}}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-grid-list>
</ng-template>



<ng-template #notFoundTemplate class="marginn main-content">
  <mat-grid-list cols="3" rowHeight="1.5:1" class="borderGrid main-content marginn">
    <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
    <mat-grid-tile class="title1">{{'qrcode.charging_Station' | translate}}</mat-grid-tile>
    <mat-grid-tile>
      <div class="example-button-row">
        <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="2" rowHeight="4:2" class="borderGrid main-content marginn">
    <mat-grid-list cols="1" rowHeight="2:1">
      <mat-grid-tile>
        <div class="text-container">
          <span class="notfound">{{'qrcode.ConnectorNotFound' | translate}}</span>
          <span class="text-item title1">{{'qrcode.call_or_message' | translate}}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-grid-list>
</ng-template>