import { Component } from '@angular/core';

@Component({
  template: `
    <div class="browser-not-supported-message-container">
      <span class="browser-not-supported-message">{{'general.not_authorized_qr' | translate}}</span>
    </div>
  `,
  styleUrls: ['not-authorized.component.scss']
})
export class NotAuthorizedComponent {
}
