import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'services/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthorizationService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const allowedRoles = route.data['roles'] as string[];
    const userRole = this.authService.getUserRole();
    const currentPath = state.url.split('?')[0];
    if (!userRole) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    if (userRole === 'Q' && !currentPath.startsWith('/qrcode')) {
      console.log(`RoleGuard: User with role ${userRole} trying to access ${currentPath}`);
      return this.router.createUrlTree(['/no-authorized']);
    }

    if (!userRole || !allowedRoles.includes(userRole)) {
      return this.router.createUrlTree(['/no-authorized']);
    }
    return true;
  }

}

