<nav class="auth-layout navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute navbar-auth" color-on-scroll="500">
  <div class="container">
    <button mat-button class="navbar-toggler pr-0" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
  </div>
</nav>
<router-outlet></router-outlet>
