<div *ngIf="!isTransactionStopped && !localSessionId; else thankYouTemplate" class="fullscreen-container">
  <mat-card class="transaction-card">
    <mat-card-title class="card-title">Enter Transaction ID</mat-card-title>
    <mat-card-content>
      <form (ngSubmit)="onSubmit()" #transactionForm="ngForm" class="transaction-form">
        <mat-form-field appearance="outline" class="form-field">
          <mat-label>Transaction ID</mat-label>
          <input matInput type="number" [(ngModel)]="transactionId" name="transactionId" required />
          <mat-error *ngIf="transactionForm.controls.transactionId?.invalid && transactionForm.controls.transactionId?.touched">
            Transaction ID is required.
          </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit" [disabled]="transactionForm.invalid" class="submit-button">
          Submit
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>

  <div class="main-content flex-grow-1 marginn" *ngIf="transactionDetails">
    <mat-grid-list cols="3" rowHeight="1.5:1">
      <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
      <mat-grid-tile class="title1">{{'charging.charging' | translate}}</mat-grid-tile>
      <mat-grid-tile> 
        <div class="example-button-row">
          <button class="help" mat-flat-button (click)="redirectToEv24()">Help</button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="1" rowHeight="5:2">
      <mat-grid-tile>
        <div class="battery"></div>
      </mat-grid-tile>
      <mat-grid-tile>
        <button class="stop" mat-flat-button (click)="stopTransaction()">{{'charging.stop' | translate}}</button>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="4:2">
      <mat-grid-list cols="3" rowHeight="1:1">
        <mat-grid-tile>
          <div >
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-container">
            <span class="text-item title1">{{'charging.price' | translate}}:</span>
            <span class="text-item">{{transactionDetails.currentCumulatedRoundedPrice}} {{transactionDetails.priceUnit}}</span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div >
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    <mat-grid-tile>
      <div class="details-container" style="width: 85%;">
        <span class="text-item title1">{{'charging.delivered' | translate}}:</span>
        <span class="text-item">{{energyInKWh}} kW.h</span>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="details-container" style="width: 85%;">
          <span class="text-item title1">{{'charging.duration' | translate}}:</span>
          <span class="text-item">{{formattedDuration}}</span>
        </div>
      </mat-grid-tile>
  </mat-grid-list>

    <!-- Additional details go here -->

    <mat-grid-list cols="1" rowHeight="24:3" class="backColor">
      <mat-grid-list cols="1" rowHeight="6:1">
        <mat-grid-tile class="title1">{{'charging.charging_Details' | translate}}</mat-grid-tile>
      </mat-grid-list>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">Id: {{transactionDetails.id}}</span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">{{'qrcode.charging_Station' | translate}}: {{transactionDetails.chargeBoxID}}</span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">{{'charging.location' | translate}}: {{ chargingStation.site.address.address1 }}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

<ng-template #thankYouTemplate>
  <div class="thank-you-container">
    <div class="thank-you-card">
      <h2 class="thank-you-title">{{'charging.thankyou' | translate}}</h2>
      <p class="thank-you-text">
        {{'charging.sessioFinished' | translate}}
      </p>
      <a [disabled]="!invoiceUrl" class="invoice-button" (click)="ViewInvoice()">
        {{isLoadingInvoice ? ('charging.chargement' | translate) : ('charging.ViewInvoice' | translate)}}
      </a>
    </div>
  </div>
</ng-template>

