/* eslint-disable @typescript-eslint/no-floating-promises */
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CentralServerService } from 'services/central-server.service';
import { LanguageService } from 'services/language.service';
import { MessageService } from 'services/message.service';
import { SpinnerService } from 'services/spinner.service';
import { WindowService } from 'services/window.service';
import { AbstractTabComponent } from 'shared/component/abstract-tab/abstract-tab.component';
import { ChargingStation, Connector, OCPPGeneralResponse } from 'types/ChargingStation';
import { ActionResponse } from 'types/DataResult';
import { Transaction } from 'types/Transaction';
import { Utils } from 'utils/Utils';

@Component({
  selector: 'app-transaction',
  templateUrl: 'transaction.component.html',
  styleUrls: ['transaction.component.scss']
})
export class TransactionCheckComponent extends AbstractTabComponent implements OnInit {
  public connectorId: number;
  public connectorTest: Connector;
  public connectorExists = true;
  public chargingStation: ChargingStation;
  public intervalId: any;
  public browserLanguage: string;
  public transactionId: number;
  public localSessionId: number;
  public formattedDuration: string;
  public energyInKWh: number;
  public transactionDetails: Transaction;
  public isLoadingInvoice = true;
  isTransactionStopped = false;
  public token: string;
  public constructor(
    activatedRoute: ActivatedRoute,
    windowService: WindowService,
    private location: Location,
    private router: Router,
    public translateService: TranslateService,
    public languageService: LanguageService,
    public centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
  ) {
    super(activatedRoute, windowService, ['all']);
  }


  public ngOnInit(): void {
    this.setupLanguagePreferences();
    this.token = localStorage.getItem('SessionToken');
  }

  redirectToCharing(): void {
    window.location.href = '/qrcode/transaction/' + this.localSessionId;
  }

  goBack(): void {
    this.location.back();
  }

  public onSubmit(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.router.navigate(['/qrcode', this.token , 'charging']);
  }

  public stopTransaction(): void {
    this.spinnerService.show();
    this.centralServerService.stopTransaction(this.transactionId).subscribe({
      next: (res: ActionResponse) => {
        this.spinnerService.hide();
        if (res.status === OCPPGeneralResponse.ACCEPTED) {
          console.log(`Transaction ${this.transactionId} stopped successfully.`);
          if (this.connectorExists) {
            this.isTransactionStopped = true;
          }
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
        } else {
          console.error('Failed to stop transaction');
        }
      },
      error: (error) => {
        this.spinnerService.hide();
        console.error('Error stopping transaction:', error);
      }
    });
  }

  public getTransactionDetails(): void {
    this.centralServerService.getTransactionQr(this.transactionId).subscribe({
      next: (transaction) => {
        this.transactionDetails = transaction;
        if (this.transactionDetails) {
          this.formattedDuration = this.convertSecondsToMinutesAndSeconds(transaction.currentTotalDurationSecs);
          this.energyInKWh = this.convertWattsToKWh(transaction.currentTotalConsumptionWh);
          localStorage.setItem('transactionId', this.transactionId.toString());
          this.localSessionId = Number(localStorage.getItem('transactionId'));
          this.loadChargingStation(this.transactionDetails.chargeBoxID, this.transactionDetails.connectorId);
        } else {
          this.transactionId = null;
          this.messageService.showErrorMessage('Transaction id does not exist');
        }
      },
      error: (error) => {
      }
    });
  }

  private async loadChargingStation(entityId: string, connectorId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.centralServerService.getChargingStationQr(entityId).subscribe({
        next: (chargingStation: ChargingStation) => {
          this.chargingStation = chargingStation;
          console.log('ChargingComponent ~ this.centralServerService.getChargingStationQr ~ chargingStation:', chargingStation);
          this.connectorExists = chargingStation.connectors.some((connector) => connector.connectorId === connectorId);
          this.connectorId = connectorId;
          this.connectorTest = Utils.getConnectorFromID(chargingStation, connectorId);
          if (this.connectorTest) {
            const statusKey = `connectorStatus.${this.connectorTest.status.toUpperCase()}`;
            this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
              this.connectorExists = true;
            });
          } else {
            this.connectorExists = false;
          }

          if (!this.connectorExists) {
            this.spinnerService.hide();
          }
          resolve();
        },
        error: (error) => {
          console.error('Failed to load charging station:', error);
          this.spinnerService.hide();
          this.connectorExists = false;
          reject(error);
        },
      });
    });
  }

  private convertSecondsToMinutesAndSeconds(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  }

  private convertWattsToKWh(watts: number): number {
    return watts / 1000;
  }

  private setupLanguagePreferences(): void {
    this.browserLanguage = this.languageService.getBrowserLanguage() || 'fr';
    localStorage.setItem('language', this.browserLanguage);
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = this.browserLanguage.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);
  }
}
