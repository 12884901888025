<nav></nav>
<div class="logo">
  <div class="simple-text logo-mini">
    <a mat-button (click)="toggleSidebar()" href="javascript:void(0)">
      <i class="material-icons">menu</i>
    </a>
  </div>
  <div *ngIf="!sidebarMinimized" class="simple-text ms-3 me-2">
    <img class="logo-img" [class.small-logo-img]="sidebarMinimized" [src]="logo" alt="">
    <div class="row-app-version">
      <a routerLink="/release-notes" class="app-version">
        {{'general.version' | translate}} {{version}}
      </a>
    </div>
  </div>
</div>
<div *ngIf="sidebarMinimized" class="logo text-center p-0">
  <div class="simple-text">
    <img class="logo-img" [class.small-logo-img]="sidebarMinimized" [src]="logo" alt="">
  </div>
</div>
<div class="sidebar-wrapper">
  <div class="user">
    <div class="photo">
      <img [src]="loggedUserImage" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseDetails" class="collapsed">
        <span>
          {{loggedUser?.name}} {{loggedUser?.firstName}}
          <b class="caret"></b>
        </span>

      </a>
      <div class="collapse" id="collapseDetails">
        <ul class="nav">
          <li *ngIf="canEditProfile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            class="nav-item">
            <a [routerLink]="['/users/profile' ]" class="nav-link"> 
              <span class="sidebar-mini"><i class="material-icons sidebar-mini-icons">person_outline</i></span>
              <span class="sidebar-normal">{{'users.profile' | translate}}</span>
            </a>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item">
            <a href="javascript:void(0)" (click)="logout()" class="nav-link">
              <span class="sidebar-mini"><i class="material-icons sidebar-mini-icons">lock_outline</i></span>
              <span class="sidebar-normal">{{'authentication.sign_out' | translate}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ul class="nav nav-content">
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngFor="let menuItem of menuItems"
      class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuItem.path]" *ngIf="menuItem.type === 'link'" class="nav-link">
        <i class="material-icons">{{menuItem.icon}}</i>
        <p>{{'general.menu.' + menuItem.title | translate}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuItem.collapse}}" *ngIf="menuItem.type === 'sub'" class="nav-link">
        <i class="material-icons">{{menuItem.icontype}}</i>
        <p>{{'general.menu.' + menuItem.title | translate}}
          <b class="caret"></b>
        </p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuItem.collapse}}" class="collapse" *ngIf="menuItem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuItem.children" class="nav-item">
            <a [routerLink]="[menuItem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
