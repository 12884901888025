<h2 class="dialog-title" mat-dialog-title>{{title | translate}}</h2>
<mat-dialog-content style="border-radius: 15px;">
  <div class="row" *ngIf="formGroup.errors">
    <div class="col-md-12">
      <mat-error class="mat-subheading-2 text-danger text-left">
        {{errorMessage}}
      </mat-error>
    </div>
  </div>
  <div class="row" *ngIf="canListUsers">
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field>
          <input matInput type="text" readonly=true placeholder="{{'users.title' | translate}}" class="form-field-popup"
            (click)="assignUser()" [formControl]="user" />
          <button mat-button matSuffix mat-icon-button aria-label="Add" (click)="assignUser()">
            <mat-icon>create</mat-icon>
          </button>
          <mat-error *ngIf="user.errors?.required">
            {{"general.mandatory_field" | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button [disabled]="!formGroup.valid" class="stop" (click)="startTransaction()" color="primary" disabled="false">{{"chargers.start_transaction" | translate}}</button>
  <button mat-raised-button (click)="cancel()" class="cancel-botton">{{'general.cancel' | translate}}</button>
</mat-dialog-actions>
