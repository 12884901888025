import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NotAuthorizedComponent } from './not-authorized.component';

@NgModule({
  declarations: [NotAuthorizedComponent],
  imports: [
    CommonModule,
    TranslateModule,
  ],
})
export class NotAuthorizedModule { }
