/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @angular-eslint/use-component-view-encapsulation */
import { Location } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SetupIntent, StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement, StripeElements, StripeError } from '@stripe/stripe-js';
import { UserMainComponent } from 'pages/users/user/main/user-main.component';
import { UserNotificationsComponent } from 'pages/users/user/notifications/user-notifications.component';
import { PaymentMethodDialogComponent } from 'pages/users/user/payment-methods/payment-method/payment-method.dialog.component';
import { UserSecurityComponent } from 'pages/users/user/security/user-security.component';
import { AuthorizationService } from 'services/authorization.service';
import { CentralServerService } from 'services/central-server.service';
import { ComponentService } from 'services/component.service';
import { MessageService } from 'services/message.service';
import { SpinnerService } from 'services/spinner.service';
import { StripeService } from 'services/stripe.service';
import { WindowService } from 'services/window.service';
import { AbstractTabComponent } from 'shared/component/abstract-tab/abstract-tab.component';
import { ChargingStation } from 'types/ChargingStation';
import { ActionResponse, BillingOperationResult } from 'types/DataResult';
import { RestResponse } from 'types/GlobalType';
import { OCPITokenType, OCPITokenWhitelist } from 'types/ocpi/OCPIToken';
import { QrTag, Tag } from 'types/Tag';
import { TenantComponents } from 'types/Tenant';
import { User, UserNotifications, UserRole } from 'types/User';
import { Utils } from 'utils/Utils';

@Component({
  selector: 'app-payment',
  templateUrl: 'payment.component.html',
  styleUrls: ['payment.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PaymentComponent extends AbstractTabComponent implements OnInit {

  @ViewChild('userNotificationsComponent') public userNotificationsComponent!: UserNotificationsComponent;
  @ViewChild('userSecurityComponent') public userSecurityComponent!: UserSecurityComponent;
  @ViewChild('userMainComponent') public userMainComponent!: UserMainComponent;
  @Input() public inDialog!: boolean;
  @Input() public dialogRef!: MatDialogRef<PaymentMethodDialogComponent>;
  @Input() public currentUserID!: string;
  @ViewChild('cardInfo', { static: true }) public cardInfo: ElementRef;
  cardForm: FormGroup;
  public canListTags: boolean;
  panelOpenState = false;
  selectedValue: string;
  cardDetails: any = {};
  stripe: any;
  userRole: UserRole;
  userData: User;
  public formGroup!: FormGroup;
  public isBillingComponentActive: boolean;
  public userID: string;
  public acceptConditions: AbstractControl;
  // Stripe elements
  public elements: StripeElements;
  public cardNumber: StripeCardNumberElement;
  public expirationDate: StripeCardExpiryElement;
  public cvc: StripeCardCvcElement;
  // Errors
  public cardNumberError: string;
  public expirationDateError: string;
  public cvcError: string;
  // conditions to enable Save
  public hasAcceptedConditions: boolean;
  public isCardNumberValid: boolean;
  public isExpirationDateValid: boolean;
  public isCvcValid: boolean;
  public isSaveClicked: boolean;
  public chargingStationId: string;
  public connectorId: number;
  public chargingStation: ChargingStation | null = null;
  loggedUser: any;
  createdUserId: string;
  visualId: string;
  private token: string;
  public constructor(
    activatedRoute: ActivatedRoute,
    windowService: WindowService,
    private router: Router,
    private location: Location,
    private fb: FormBuilder,
    private centralServerService: CentralServerService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private componentService: ComponentService,
    public translateService: TranslateService,
    private authorizationService: AuthorizationService,
    private route: ActivatedRoute,
    private stripeService: StripeService
  ) {
    super(activatedRoute, windowService, ['all']);
    this.isBillingComponentActive = this.componentService.isActive(TenantComponents.BILLING);
    this.hasAcceptedConditions = false;
    this.isCardNumberValid = false;
    this.isExpirationDateValid = false;
    this.isCvcValid = false;
    this.isSaveClicked = false;
  }

  public ngOnInit(): void {
    this.spinnerService.hide();
    this.createdUserId = localStorage.getItem('userId');
    this.loggedUser = this.centralServerService.getLoggedUser();
    console.log('PaymentComponent ~ ngOnInit ~ loggedUser:', this.loggedUser);
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
    const token = Utils.decodeTokenHeader(this.token);

    const language = localStorage.getItem('language');
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = language.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);
    const entityId = token.chargingStationID;
    const connectorId = Number(token.connectorID);
    this.chargingStationId = entityId;
    this.connectorId = connectorId;
    void this.initialize();
    this.selectedValue = '1';
    this.createForm();
  }

  createForm() {
    this.cardForm = this.fb.group({
      acceptConditions: [false, Validators.requiredTrue],
    });
  }

  isCardFormValid(): boolean {
    return this.isCardNumberValid && this.isExpirationDateValid && this.isCvcValid;
  }

  redirectToEv24(): void {
    window.location.href = 'https://www.ev24.io/support/';
  }

  goBack(): void {
    this.location.back();
  }

  public createAllTrueNotifications = (): UserNotifications => ({
    sendSessionStarted: true,
    sendOptimalChargeReached: true,
    sendEndOfCharge: true,
    sendEndOfSession: true,
    sendUserAccountStatusChanged: true,
    sendNewRegisteredUser: true,
    sendUnknownUserBadged: true,
    sendChargingStationStatusError: true,
    sendChargingStationRegistered: true,
    sendOcpiPatchStatusError: true,
    sendOicpPatchStatusError: true,
    sendUserAccountInactivity: true,
    sendPreparingSessionNotStarted: true,
    sendOfflineChargingStations: true,
    sendBillingSynchronizationFailed: true,
    sendBillingPeriodicOperationFailed: true,
    sendSessionNotStarted: true,
    sendCarCatalogSynchronizationFailed: true,
    sendComputeAndApplyChargingProfilesFailed: true,
    sendEndUserErrorNotification: true,
    sendBillingNewInvoice: true,
    sendAdminAccountVerificationNotification: true,
  });

  public async onSubmit(): Promise<void> {
    console.log('PaymentComponent ~ onSubmit ~ this.cardForm.valid:', this.cardForm.valid);
    if (this.cardForm.valid && this.cardForm.controls['acceptConditions'].value && this.isCardFormValid()) {
      try {
        const createdUserId = localStorage.getItem('userId');
        const setupIntent = await this.createSetupIntent(createdUserId);
        if (!setupIntent) {
          this.messageService.showErrorMessage('Failed to create setup intent');
          return;
        }
        const confirmationResult = await this.confirmSetupIntent(setupIntent);
        if (confirmationResult.error) {
          this.messageService.showErrorMessage('Failed to confirm payment method');
          return;
        }
        const attachResult = await this.attachPaymentMethod(confirmationResult, createdUserId);
        if (attachResult.error) {
          this.messageService.showErrorMessage('Failed to attach payment method',);
          return;
        }
        const tagID = await this.assignTagToUser(this.loggedUser);
        localStorage.setItem('tagId', tagID);
        localStorage.setItem('userId', createdUserId);
        Utils.decodeTokenHeader(this.token);
        this.router.navigate([`/qrcode/${this.token}/charging`]);
      } catch (error) {
        this.messageService.showErrorMessage('An unexpected error occurred');
      }
    } else {
      this.messageService.showErrorMessage('Form is invalid or conditions are not accepted');
    }
  }

  public linkCardToAccount(userId: string) {
    this.isSaveClicked = true;
    void this.doCreatePaymentMethod(userId);
  }

  public handleAcceptConditions() {
    this.hasAcceptedConditions = !this.hasAcceptedConditions;
  }

  public close(saved: boolean = false) {
    if (this.inDialog) {
      this.dialogRef.close(saved);
    }
  }

  private async doCreatePaymentMethod(userId: string){
    const operationResult: any = await this.createPaymentMethod(userId);
    if (operationResult.error) {
      if (operationResult.error.code === 'card_declined') {
        this.isCardNumberValid = false;

        this.messageService.showErrorMessage('settings.billing.payment_methods_create_error_card_declined');
        this.cardNumberError = this.translateService.instant('settings.billing.payment_methods_card_declined');
        this.cardNumber.focus();
      } else {
        this.messageService.showErrorMessage('settings.billing.payment_methods_create_error');
      }
      this.isSaveClicked = false;
    } else {
      this.messageService.showSuccessMessage('settings.billing.payment_methods_create_success', { last4: operationResult.internalData.card.last4 });
      this.close(true);
    }
  }


  private async createPaymentMethod(userId: string): Promise<any> {
    try {
      const setupIntent = await this.createSetupIntent(userId);
      const confirmationResult = await this.confirmSetupIntent(setupIntent);
      if (confirmationResult.error) {
        return confirmationResult;
      }
      return this.attachPaymentMethod(confirmationResult, userId);
    } catch (error) {
      Utils.handleHttpError(error, this.router, this.messageService, this.centralServerService, 'general.unexpected_error_backend');
    }
  }

  private async createSetupIntent(userId: string): Promise<any> {
    try {
      this.spinnerService.show();
      const response: BillingOperationResult = await this.centralServerService.setupPaymentMethod({ userID: userId }).toPromise();
      return response?.internalData;
    } finally {
      this.spinnerService.hide();
    }
  }

  private async confirmSetupIntent(setupIntent: SetupIntent): Promise<{ setupIntent?: SetupIntent; error?: StripeError }> {
    return this.getStripeFacade().confirmCardSetup(setupIntent.client_secret, {
      payment_method: {
        card: this.cardNumber,
      },
    });
  }

  private async attachPaymentMethod(confirmationResult: { setupIntent?: SetupIntent; error?: StripeError }, userId: string): Promise<BillingOperationResult> {
    try {
      this.spinnerService.show();
      const response: BillingOperationResult = await this.centralServerService.setupPaymentMethod({
        setupIntentId: confirmationResult.setupIntent?.id,
        paymentMethodId: confirmationResult.setupIntent?.payment_method,
        userID: userId,
      }).toPromise();
      return response;
    } finally {
      this.spinnerService.hide();
    }
  }

  private async initialize(): Promise<void> {
    try {
      this.spinnerService.show();
      const stripeFacade = await this.stripeService.initializeStripe();
      if (!stripeFacade) {
        this.messageService.showErrorMessage('settings.billing.not_properly_set');
      } else {
        this.initializeCardElements();
      }
    } catch (error) {
      Utils.handleHttpError(error, this.router, this.messageService, this.centralServerService, 'general.unexpected_error_backend');
    } finally {
      this.spinnerService.hide();
    }
  }

  private getStripeFacade() {
    return this.stripeService.getStripeFacade();
  }


  private initializeCardElements() {
    this.elements = this.getStripeFacade().elements();
    this.cardNumber = this.elements.create('cardNumber');
    this.cardNumber.mount('#cardNumber');
    this.cardNumber.on('change', event => {
      this.cardNumberError = event.error ? this.translateService.instant('settings.billing.payment_methods_card_number_error') : '';
      this.isCardNumberValid = !event.error && event.complete;
    });

    this.expirationDate = this.elements.create('cardExpiry');
    this.expirationDate.mount('#cardExp');
    this.expirationDate.on('change', event => {
      this.expirationDateError = event.error ? this.translateService.instant('settings.billing.payment_methods_expiration_date_error') : '';
      this.isExpirationDateValid = !event.error && event.complete;
    });

    this.cvc = this.elements.create('cardCvc');
    this.cvc.mount('#cardCvc');
    this.cvc.on('change', event => {
      this.cvcError = event.error ? this.translateService.instant('settings.billing.payment_methods_cvc_error') : '';
      this.isCvcValid = !event.error && event.complete;
    });
  }

  private async assignTagToUser(user: User): Promise<string> {
    return new Promise((resolve,reject) => {
      const tagID = Utils.generateTagID();
      const tag: Tag = {
        id: tagID,
        default: true,
        active: true,
        issuer: true,
        userID: user.id,
        visualID: tagID,
        description: 'Auto-generated tag for user',
        emspName: '',
      };
      console.log('PaymentComponent ~ returnnewPromise ~ tag:', tag);
      this.centralServerService.createQrTag(tag).subscribe({
        next: (response: ActionResponse) => {
          if (response.status === RestResponse.SUCCESS) {
            console.log('Tag created successfully:', response.id);
            resolve(tagID);
          } else {
            this.messageService.showErrorMessage('Failed to create tag');
            reject(new Error('Failed to create tag'));
          }
        },
        error: (error) => {
          this.spinnerService.hide();
          Utils.handleHttpError(error, this.router, this.messageService, this.centralServerService, 'tags.create_error');
          reject(new Error('Failed to create tag'));
        }
      });

    });
  }
}
