import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
})
export class LoadingButtonComponent {
  isLoading = false;

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }
}
