<div class="main-content vw-90 vh-90 shared-dialog-size">
  <div class="card card-profile card-testimonial">
    <mat-tab-group animationDuration="0ms" disableRipple="true" class="mat-tab-info mat-tabs-with-actions">
      <mat-tab>
        <ng-template mat-tab-label>
          <span>{{title | translate}}</span>
        </ng-template>
        <div class="card-body mat-tab-dialog-body-content pt-0">
          <div class="tab-content">
              <app-table [dataSource]="dialogDataSource"></app-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class="tabs-actions">
      <button mat-icon-button (click)="validate()" [disabled]="dialogDataSource.selectedRows === 0">
        <mat-icon>done</mat-icon>
      </button>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
